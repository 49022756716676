import { OnlinePortalApplicationDocumentRequest } from '@/models';
import { AutoFactory, CollectionResponseApplicationDocumentRequest, DocumentName, OnlinePortalApplicationDocumentRequestsService } from '@sageworks/jpi';
import { GroupedDocumentRequests } from '@/models/GroupedDocumentRequests';
import { getObjectKeys } from '@/utils/ObjectUtils';

/**
 * Retrieves all applicationDocumentRequests and applicationDocumentRequests from the JPI
 * Returns an array of OnlinePortalApplicationDocumentRequest
 *
 * @param page default: 1
 * @param perPage default: 1000
 */

export async function getApplicationDocumentRequests(
	loanApplicationId: number,
	page?: number,
	perPage?: number
): Promise<OnlinePortalApplicationDocumentRequest[]> {
	page = page ? page : 1;
	perPage = perPage ? perPage : 1000;

	const onlinePortalApplicationDocumentRequestsService: OnlinePortalApplicationDocumentRequestsService = AutoFactory.get(
		OnlinePortalApplicationDocumentRequestsService
	);

	const applicationDocumentRequests: CollectionResponseApplicationDocumentRequest = await onlinePortalApplicationDocumentRequestsService.getPaged(
		page,
		perPage,
		loanApplicationId
	);

	let newApplicationDocumentRequests = applicationDocumentRequests.items || [];
	// Transform ApplicationDocumentRequests into Online-Portal-specific OnlinePortalApplicationDocumentRequests
	return newApplicationDocumentRequests.map(documentRequest => new OnlinePortalApplicationDocumentRequest(documentRequest));
}

export function transformApplicationDocumentRequestsForBorrowerView(
	documentNames: DocumentName[],
	newApplicationDocumentRequests: OnlinePortalApplicationDocumentRequest[]
): OnlinePortalApplicationDocumentRequest[] {
	// Don't show waived requests in the borrower view, show for lender for un-waiving
	newApplicationDocumentRequests = newApplicationDocumentRequests.filter(
		documentRequest => !documentRequest.dateWaived && !documentRequest.waivedReason && !documentRequest.waivedByLoanOfficerId
	);

	// If we are in Borrower view then we need to change the name of the document to a Customer facing name
	newApplicationDocumentRequests.forEach(documentRequest => {
		if (!documentRequest.documentNamesId) return;
		let documentName: DocumentName | undefined = documentNames.find(documentName => documentName.id === documentRequest.documentNamesId);

		if (documentName?.customerFacingName != null && documentName.customerFacingName.trim() !== '') {
			documentRequest.documentName = documentName.customerFacingName;
		}
	});
	return newApplicationDocumentRequests;
}

export function groupAndSortByCustomerId(documentRequests: OnlinePortalApplicationDocumentRequest[]): GroupedDocumentRequests {
	const map = {} as GroupedDocumentRequests;

	documentRequests.forEach((request: OnlinePortalApplicationDocumentRequest) => {
		const customerId = request.customerId;

		if (customerId) {
			if (map[customerId] == null) {
				map[customerId] = [];
			}

			map[customerId]?.push(request);
		}
	});

	for (const key of getObjectKeys(map)) {
		map[key].sort((a: OnlinePortalApplicationDocumentRequest, b: OnlinePortalApplicationDocumentRequest) => {
			// If we have two requests with the same position, or one of them is missing position, fallback to alphabetical sort
			if (a.position === null || a.position === undefined || b.position === null || b.position === undefined || a.position - b.position === 0)
				return (a.documentName || '') < (b.documentName || '') ? -1 : 1;
			return a.position - b.position;
		});
	}

	return map;
}

export async function createApplicationDocumentRequest(
	requestToCreate: OnlinePortalApplicationDocumentRequest
): Promise<OnlinePortalApplicationDocumentRequest> {
	const onlinePortalApplicationDocumentRequestsService: OnlinePortalApplicationDocumentRequestsService = AutoFactory.get(
		OnlinePortalApplicationDocumentRequestsService
	);
	var newRequest = await onlinePortalApplicationDocumentRequestsService.create(requestToCreate.request);
	return new OnlinePortalApplicationDocumentRequest(newRequest);
}

export async function updateApplicationDocumentRequest(requestToUpdate: OnlinePortalApplicationDocumentRequest): Promise<void> {
	const onlinePortalApplicationDocumentRequestsService: OnlinePortalApplicationDocumentRequestsService = AutoFactory.get(
		OnlinePortalApplicationDocumentRequestsService
	);
	onlinePortalApplicationDocumentRequestsService.update(requestToUpdate.id as number, requestToUpdate.request);
}

export async function deleteApplicationDocumentRequest(requestToDelete: OnlinePortalApplicationDocumentRequest): Promise<void> {
	if (!requestToDelete.id) return;
	const onlinePortalApplicationDocumentRequestsService: OnlinePortalApplicationDocumentRequestsService = AutoFactory.get(
		OnlinePortalApplicationDocumentRequestsService
	);
	onlinePortalApplicationDocumentRequestsService._delete(requestToDelete.id);
}

export async function reopen(requestToReopen: OnlinePortalApplicationDocumentRequest): Promise<OnlinePortalApplicationDocumentRequest> {
	(requestToReopen.dateUploaded as any) = null;
	(requestToReopen.uploadedByUsersId as any) = null;
	(requestToReopen.uploadedByUserType as any) = null;
	(requestToReopen.uploadedByUserName as any) = null;
	(requestToReopen.dateWaived as any) = null;
	(requestToReopen.waivedReason as any) = null;
	(requestToReopen.waivedByLoanOfficerId as any) = null;
	(requestToReopen.waivedByLoanOfficerName as any) = null;
	(requestToReopen.documentId as any) = null;
	(requestToReopen.notUploadedReason as any) = null;
	await updateApplicationDocumentRequest(requestToReopen);
	return Promise.resolve(requestToReopen);
}

export function getStatusMessageForDocumentRequest(documentRequest: OnlinePortalApplicationDocumentRequest) {
	let docStatusMessage = 'Open Request';

	if (documentRequest.dateUploaded) {
		docStatusMessage = `Uploaded ${documentRequest.dateUploaded}`;
	} else if (documentRequest.dateWaived && documentRequest.waivedReason === 'Document on file') {
		docStatusMessage = `Marked on file ${documentRequest.dateWaived}`;
	} else if (documentRequest.dateWaived) {
		docStatusMessage = `Waived ${documentRequest.dateWaived} for reason "${documentRequest.waivedReason}"`;
	}

	return docStatusMessage;
}
