export default {
	weight: 1450,
	type: 'checkbox',
	defaultValue: false,
	disabled: false,
	input: true,
	key: 'forceRenderInPdf',
	label: 'Force to Show in Exported PDF',
	tooltip: 'With this setting enabled, all contents within this panel component will render in the application PDF.'
};
