import BaseRepeaterWidget from '../base/base-repeater-widget/base-repeater-widget';
import editForm from './collateral-info.form';
import { CustomBuilderGroups, CustomComponentType, FetchDataType, ContextDataProperty } from '../../../enums';
import { CollateralAddRowPopup } from '../collateral-add-row-popup/collateral-add-row-popup';
import { ComponentDataHelper } from '../../../utils';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { AutoFactory, OnlinePortalCustomersService as CustomersService } from '@sageworks/jpi';
import { Utils as FormioUtils } from 'formiojs';
import { PopupWrapper } from '../popup';
import FormComponent from 'formiojs/components/form/Form';
import { CollateralWithCustomerName } from './collateral-with-customer-name';
import { showLoadingPopup } from '../../../utils/modals/loading-modal-helper';
export default class CollateralInfoRepeaterWidget extends BaseRepeaterWidget {
	protected showEditGridNav = false;

	static schema(...extend: any) {
		return BaseRepeaterWidget.schema(
			{
				label: 'Collateral Info',
				type: CustomComponentType.collateralInfo,
				key: CustomComponentType.collateralInfo,
				addAnother: 'Add Collateral'
			},
			...extend
		);
	}

	private get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}

	private get isLender() {
		return this.userInfo == null || this.userInfo?.proxyUserId != null;
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Collateral Info',
			group: CustomBuilderGroups.widgets,
			weight: 10,
			schema: CollateralInfoRepeaterWidget.schema({
				skipRemoveConfirm: true
			})
		};
	}

	public init() {
		super.init();
	}

	public async attach(element: any) {
		if (this.hasOpenRows()) {
			this.emitEditEvent();
		}

		return super.attach(element);
	}

	protected async saveOrEditRow(rowIndex: number, data: any) {
		const formComponent: FormComponent = this.editRows[rowIndex].components[0];
		await formComponent.subFormReady;
		formComponent.setValue(
			{
				...formComponent.subForm.getValue(),
				data: data
			},
			{ fromSubmission: true }
		);

		this.editRows[rowIndex].data = formComponent.data;
		await this.saveNewRow(rowIndex);
	}

	async saveNewRow(rowIndex: number) {
		this.saveRow(rowIndex);

		const savePromise = this.partialSaveRow(rowIndex).finally(() => {
			return this.editRow(rowIndex);
		});
		showLoadingPopup(savePromise);

		await savePromise;
	}

	addRowModal(rowIndex: number): any {
		var editRow = this.editRows[rowIndex];

		if (editRow.state === 'new') {
			this.showAddRowPopup(rowIndex);
		} else {
			return super.addRowModal(rowIndex);
		}
	}

	async showAddRowPopup(rowIndex: number): Promise<void> {
		const addRowPopupPromise = this.createAddRowPopup(rowIndex);
		showLoadingPopup(addRowPopupPromise);
		const addRowPopup = await addRowPopupPromise;
		const popupWrapper = new PopupWrapper({}, {}, {}, addRowPopup);

		popupWrapper.showPopup();
		const data = await popupWrapper.actionCompleted;

		if (data == null) {
			// No customer was selected, let Formio handle the cancel logic to reset the row to the proper state
			this.cancelRow(rowIndex);
			return;
		}

		try {
			// Timeout is required, if we call saveOrEditRow immediately, the editGrid/primary role component will be replaced by the single editRow
			// This only seems to affect borrower view, and only if we skip the collateral-add-row-popup, so we don't want a delay if we're a lender
			if (this.isLender) {
				await this.saveOrEditRow(rowIndex, data);
			} else {
				setTimeout(async () => {
					await this.saveOrEditRow(rowIndex, data);
				}, 1000);
			}
		} finally {
			//
		}
	}

	private async createAddRowPopup(rowIndex: number) {
		const ignoredCollateralIds = this.getAllCollateralIds();
		const editRow = this.editRows[rowIndex];
		const collateralObjectList = await this.getCollateralObjects();
		return new CollateralAddRowPopup(
			CollateralAddRowPopup.schema({
				collateralObjectList: collateralObjectList,
				ignoredCollateralIds: ignoredCollateralIds
			}),
			{
				...this.options,
				skipInit: false
			},
			(FormioUtils as any).fastCloneDeep(editRow.data)
		);
	}

	private async getCollateralObjects() {
		const collateralObjectList = await ComponentDataHelper.fetchData<CollateralWithCustomerName[]>(this, {
			fetchType: FetchDataType.Collateral,
			fetchContext: { isLender: this.isLender }
		});
		const ids = collateralObjectList
			.map<number>(collateral => {
				return collateral.customerId;
			})
			.filter((value, index, self) => {
				return self.indexOf(value) === index;
			});
		const customersService = AutoFactory.get(CustomersService);
		if (ids.length < 1) return collateralObjectList;
		const customers = (await customersService.getPaged(1, 1000, undefined, ids)).items ?? [];
		collateralObjectList.forEach(collateral => {
			const customer = customers.find(customer => {
				return customer.id === collateral.customerId;
			});
			if (!customer) {
				collateral.customerName = '';
				return;
			}
			collateral.customerName = customer.name;
		});
		return collateralObjectList;
	}

	rowDescription(row: any, rowIndex: number): string {
		try {
			if (!this.rowDescriptionSubformLoaded(rowIndex)) {
				return 'Loading...';
			}

			const { collateralDescriptionKey } = this.getDescriptionKeys();

			return row.data[collateralDescriptionKey];
		} catch (error) {
			return 'Unable to load preview';
		}
	}

	editRow(rowIndex: number) {
		const result: any = super.editRow(rowIndex);
		this.emitEditEvent();

		return result;
	}

	addRow(): any {
		this.component.modal = true;
		let newRow = undefined;
		try {
			newRow = super.addRow();
		} finally {
			this.component.modal = false;
		}

		return newRow;
	}

	public saveRow(rowIndex: number, modified?: boolean) {
		const result = super.saveRow(rowIndex, modified);

		if (result) {
			this.emitEditEndEvent();
		}

		return result;
	}

	cancelRow(rowIndex: number) {
		const editRow = this.editRows[rowIndex];
		const originalState = editRow.state;

		const result = super.cancelRow(rowIndex);

		if (originalState !== editRow.state) {
			this.emitEditEndEvent();
		}

		return result;
	}

	protected getAllCollateralIds() {
		return this.editRows.map<number>(editRow => {
			const data = editRow?.data?.form?.data ?? {};

			if (data[CustomComponentType.collateralInfo]) {
				return data[CustomComponentType.collateralInfo]?.data?.id;
			} else {
				return null;
			}
		});
	}
}
