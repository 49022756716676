import { FormDataLookup, RenderDataLookup } from '@/models';
import { DataStatus, DataStatusLookup } from '@/models/DataStatusLookup';
import { getEntitiesOnLoan } from '@sageworks/dynamic-forms/src/utils/form-data-helpers/form-data-loan-role-helpers';
import {
	ApplicationTemplate,
	AutoFactory,
	CollectionResponseProposedProductSelectOption,
	DocumentLibrarySavePdfFromHtmlRequest,
	DynamicApplicationMessage,
	DynamicApplicationMessagesService,
	FeatureFlagging,
	FormioRenderDataModel,
	GetProposedProductSelectOptionParams,
	HMDAGMIService,
	LoanApplication,
	LoanApplicationValidationResult,
	LoanOfficer,
	OnlinePortalFormDataService,
	OnlinePortalLoanApplicationProposedLoanMappingsService,
	OnlinePortalLoanApplicationsService,
	OnlinePortalLoanApplicationValidationService,
	OnlinePortalLoanOfficersService,
	OnlinePortalRenderEngineDataService,
	ProposedProductSelectOption,
	ProposedProductSelectOptionsService,
	OnlinePortalEmailService
} from '@sageworks/jpi';
import { defineModule } from 'direct-vuex';
import Vue from 'vue';
import { moduleActionContext, moduleGetterContext } from '.';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, LoanApplicationDataModule);
// eslint-disable-next-line no-use-before-define
const getterContext = (context: any) => moduleGetterContext(context, LoanApplicationDataModule);

export interface LoanApplicationDataModuleState {
	loanApplication: LoanApplication | null;
	loanOfficer: LoanOfficer | null;
	formDataLookup: FormDataLookup;
	dynamicApplicationMessage: DynamicApplicationMessage;
	loanDataStatusLookup: DataStatusLookup;
	renderDataLookup: RenderDataLookup;
	proposedProductSelectOptions: ProposedProductSelectOption[];
	validationResults: LoanApplicationValidationResult;
}

const LoanApplicationDataModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			loanApplication: null,
			loanOfficer: null,
			formDataLookup: {},
			dynamicApplicationMessage: {},
			loanDataStatusLookup: {},
			renderDataLookup: {},
			proposedProductSelectOptions: [],
			validationResults: {},
			refinanceData: {}
		} as LoanApplicationDataModuleState;
	},
	mutations: {
		SET_FORM_DATA(state, { formData, loanMappingId }) {
			Vue.set(state.formDataLookup, loanMappingId, formData);
		},
		SET_LOAN_APPLICATION(state, { loanApplication }: { loanApplication: LoanApplication }) {
			state.loanApplication = loanApplication;
		},
		SET_LOAN_OFFICER(state, loanOfficer: LoanOfficer) {
			state.loanOfficer = loanOfficer;
		},
		SET_APPLICATION_TERMS_AND_CONDITIONS(state, dynamicApplicationMessage: DynamicApplicationMessage) {
			state.dynamicApplicationMessage = dynamicApplicationMessage;
		},
		SET_DATA_STATUS(state, { dataStatus, loanMappingId }: { dataStatus: DataStatus; loanMappingId: number }) {
			Vue.set(state.loanDataStatusLookup, loanMappingId, dataStatus);
		},
		SET_RENDER_DATA(state, { renderData, loanMappingId }: { renderData: FormioRenderDataModel; loanMappingId: number }) {
			Vue.set(state.renderDataLookup, loanMappingId, renderData);
		},
		CLEAR_ALL_FORM_DATA(state) {
			state.formDataLookup = {};
			state.renderDataLookup = {};
		},
		SET_PROPOSED_PRODUCT_SELECT_OPTIONS(state, selectOptions: ProposedProductSelectOption[]) {
			state.proposedProductSelectOptions = selectOptions;
		},
		SET_VALIDATION_RESULTS(state, validationResults: LoanApplicationValidationResult) {
			state.validationResults = validationResults;
		}
	},
	actions: {
		async loadLoanApplicationBundle(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { dispatch } = actionContext(context);

			await dispatch.loadLoanApplication({ loanApplicationId });
			await dispatch.fetchLoanOfficer();
		},
		async fetchLoanOfficer(context): Promise<void> {
			const { state, commit } = actionContext(context);
			if (state.loanApplication?.bankLoanOfficerID != null) {
				const service = AutoFactory.get(OnlinePortalLoanOfficersService);
				const loanOfficer = await service.getLoanOfficerById(state.loanApplication.bankLoanOfficerID);
				commit.SET_LOAN_OFFICER(loanOfficer);
			}
		},
		async loadLoanApplication(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const service = AutoFactory.get(OnlinePortalLoanApplicationsService);

			const response = await service.getById(loanApplicationId);
			commit.SET_LOAN_APPLICATION({ loanApplication: response });
		},
		async fetchApplicationTermsAndCondition(
			context,
			{ applicationTemplateType }: { applicationTemplateType: ApplicationTemplate.TypeEnum }
		): Promise<void> {
			const { commit } = actionContext(context);

			const dynamicApplicationMessagesService = AutoFactory.get(DynamicApplicationMessagesService);
			const applicationTermsAndConditions = await dynamicApplicationMessagesService.getPaged(
				1,
				1,
				[applicationTemplateType],
				[DynamicApplicationMessage.MessageTypeEnum.TermsAndConditions]
			);
			if (applicationTermsAndConditions.items && applicationTermsAndConditions.items.length > 0) {
				commit.SET_APPLICATION_TERMS_AND_CONDITIONS(applicationTermsAndConditions.items[0]);
			}
		},
		async loadFormData(context, { loanMappingId }: { loanMappingId: number }): Promise<void> {
			const { commit, state } = actionContext(context);
			let dataStatus = state.loanDataStatusLookup[loanMappingId];

			if (dataStatus == null) {
				dataStatus = { isLoading: false, error: null };
				commit.SET_DATA_STATUS({ dataStatus, loanMappingId });
			}

			// No need to load data again if it's currently being fetched, failed or already fetched
			if (dataStatus.isLoading || dataStatus.error || state.formDataLookup[loanMappingId]) {
				return;
			}

			try {
				commit.SET_DATA_STATUS({ dataStatus: { isLoading: true, error: null }, loanMappingId });

				const service = AutoFactory.get(OnlinePortalFormDataService);
				var response = await service.getFormData(loanMappingId);
				commit.SET_FORM_DATA({ formData: response, loanMappingId: loanMappingId });
				commit.SET_DATA_STATUS({ dataStatus: { isLoading: false, error: null }, loanMappingId });
			} catch (err) {
				commit.SET_DATA_STATUS({ dataStatus: { isLoading: false, error: err }, loanMappingId });
			}
		},
		async loadRenderData(context, { loanMappingId }: { loanMappingId: number }): Promise<void> {
			const { commit, state } = actionContext(context);

			if (state.renderDataLookup[loanMappingId]) {
				return;
			}

			const service = AutoFactory.get(OnlinePortalRenderEngineDataService);
			const renderData = await service.getFormioRenderData(loanMappingId, true);

			commit.SET_RENDER_DATA({ renderData, loanMappingId });
		},
		async updateApplicationStatus(context, { applicationStatus }: { applicationStatus: LoanApplication.ApplicationStatusEnum }) {
			const { commit, state } = actionContext(context);
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			const loanApplicationId = state.loanApplication?.id;
			const loanApplication = await loanApplicationService.update(loanApplicationId!, {
				applicationStatus
			} as any);

			commit.SET_LOAN_APPLICATION({ loanApplication });
		},
		async addProductToApplication(_, { proposedProductId, loanApplicationId }: { proposedProductId: number; loanApplicationId: number }): Promise<any> {
			const loanApplicationLoanService = AutoFactory.get(OnlinePortalLoanApplicationProposedLoanMappingsService);
			const loanApplicationLoan = await loanApplicationLoanService.addLoanToApplication(loanApplicationId, proposedProductId);
			return loanApplicationLoan;
		},
		async sendEmailLoanReadyForBorrowerSubmission(_, { loanApplicationId }: { loanApplicationId: number }): Promise<void> {
			const onlinePortalEmailService = AutoFactory.get(OnlinePortalEmailService);
			await onlinePortalEmailService.send(loanApplicationId);
		},
		async validateLoanApplication(context, { loanApplicationId }: { loanApplicationId: number }) {
			const service = AutoFactory.get(OnlinePortalLoanApplicationValidationService);
			const response = await service.validateLoanApplication(loanApplicationId);
			const { commit } = actionContext(context);
			if (response.results) {
				commit.SET_VALIDATION_RESULTS(response);
			}
			return response;
		},
		async clearAllFormData(context) {
			const { commit } = actionContext(context);

			commit.CLEAR_ALL_FORM_DATA();
		},
		async submitApplication(context): Promise<void> {
			const { state, commit, rootGetters, rootDispatch } = actionContext(context);
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			const hmdaGmiService = AutoFactory.get(HMDAGMIService);

			const loanApplicationId = state.loanApplication?.id;
			const shouldLock = rootGetters.InstitutionSettings.shouldAutoLockOnSubmit(state.loanApplication?.type!);
			let submittedLoanApplication = await loanApplicationService.submitById({ id: loanApplicationId });

			const enableHmdaGmi = await rootDispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnableHmdainDya);
			if (enableHmdaGmi) {
				await hmdaGmiService.autoCreateHmdaRecordsByApplicationId(loanApplicationId);
			}

			if (shouldLock) {
				submittedLoanApplication = await loanApplicationService.lockApplication(loanApplicationId);
			}

			rootDispatch.Application.updateLoanApplication(submittedLoanApplication);
			commit.SET_LOAN_APPLICATION({ loanApplication: submittedLoanApplication });
		},
		async saveApplicationPdfToDocumentLibrary(context, { html }): Promise<void> {
			const { state } = actionContext(context);
			const service = AutoFactory.get(OnlinePortalLoanApplicationsService);
			const loanApplicationId = state.loanApplication?.id;
			return service.saveApplicationPdfToDocumentLibrary(loanApplicationId, { html } as DocumentLibrarySavePdfFromHtmlRequest);
		},
		async loadProposedProductOptions(context): Promise<void> {
			const { state, commit, rootGetters } = actionContext(context);
			const proposedProductOptionsService = AutoFactory.get(ProposedProductSelectOptionsService);
			const selectOptionResponse: CollectionResponseProposedProductSelectOption = await proposedProductOptionsService.getOptions({
				applicationType: state.loanApplication?.type,
				preAppTemplateId: rootGetters.ApplicationTemplates.preAppTemplateId
			} as GetProposedProductSelectOptionParams);
			commit.SET_PROPOSED_PRODUCT_SELECT_OPTIONS(selectOptionResponse.items || []);
		},
		async removeComponentValidationError(context, { loanMappingId, componentId }: { loanMappingId: number; componentId: string }) {
			const { state, commit } = actionContext(context);

			const results = state.validationResults?.results ?? {};
			if (!results || !results[loanMappingId].errors) return;

			const updatedErrors = results[loanMappingId].errors?.filter(x => x.componentId !== componentId);
			const updatedResults: LoanApplicationValidationResult = {
				results: {
					...results,
					[loanMappingId]: {
						isValid: updatedErrors?.length === 0,
						errors: updatedErrors
					}
				}
			};

			commit.SET_VALIDATION_RESULTS(updatedResults);
		},
		// eslint-disable-next-line max-lines-per-function
		async addPageValidationError(context, { loanMappingId, pageId, errors }: { loanMappingId: number; pageId: string; errors: any[] }) {
			const { state, commit } = actionContext(context);
			const invalidRowsError = 'Please correct invalid rows before proceeding.';
			const existingValidationErrors = state.validationResults?.results ?? {};
			let updatedValidation: LoanApplicationValidationResult = {};

			let newErrors = errors.map(error => {
				return { componentId: error.component.id, componentPath: [pageId], message: error.message };
			});
			newErrors = newErrors.filter(error => error.message !== invalidRowsError);

			if (existingValidationErrors[loanMappingId]) {
				const currentPageErrors = existingValidationErrors[loanMappingId]?.errors ?? [];
				const updatedErrorList = currentPageErrors.filter(x => x.componentPath![0] !== pageId).concat(newErrors);

				updatedValidation = {
					results: {
						...existingValidationErrors,
						[loanMappingId]: {
							isValid: updatedErrorList.length === 0,
							errors: updatedErrorList
						}
					}
				};
			} else {
				updatedValidation = {
					results: {
						...existingValidationErrors,
						[loanMappingId]: {
							isValid: newErrors.length === 0,
							errors: newErrors
						}
					}
				};
			}
			commit.SET_VALIDATION_RESULTS(updatedValidation);
		}
	},
	getters: {
		loansByCustomerId(...args): Map<number, number[]> {
			const { state, rootState } = getterContext(args);
			const map = new Map<number, number[]>();
			for (const metadata of rootState.LoanApplicationMetadata.metadata) {
				const formData = state.renderDataLookup[metadata.loanMappingId ?? 0];
				if (!formData?.formData || !metadata.proposedLoanId) {
					continue;
				}
				for (const entityId of getEntitiesOnLoan(formData.formData)) {
					if (map.has(entityId)) {
						const existingList = map.get(entityId) ?? [];
						// extracting this to multiple functions is more confusing than the depth of 4
						// eslint-disable-next-line max-depth
						if (existingList && existingList.indexOf(metadata.proposedLoanId) === -1) {
							existingList.push(metadata.proposedLoanId);
						}
					} else {
						map.set(entityId, [metadata.proposedLoanId]);
					}
				}
			}
			return map;
		},
		loanOfficerFullName: state => {
			const firstName = state.loanOfficer?.firstName ?? '';
			const lastName = state.loanOfficer?.lastName ?? '';

			return `${firstName} ${lastName}`;
		},
		loanApplicationSubmittedDate: state => {
			return state.loanApplication?.submittedDate ?? null;
		},
		applicationStatus: state => {
			return state.loanApplication?.applicationStatus ?? null;
		},
		applicationTermsAndConditionsMessage: state => {
			return state.dynamicApplicationMessage.message;
		},
		componentContainValidationError: state => {
			const results = state.validationResults.results ?? {};

			return (loanMappingId: number, componentId: string) => {
				const { errors = [] } = results[loanMappingId] ?? {};

				return errors.some(x => x.componentId === componentId);
			};
		}
	}
});

export default LoanApplicationDataModule;
