
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { OnlinePortalApplicationDocumentRequest } from '@/models';
import {
	SbaForm,
	DynamicApplicationDocumentSetting,
	AutoFactory,
	DynamicApplicationDocumentSettingsService,
	UsableProducts,
	Customer,
	FeatureFlagging,
	Permission
} from '@sageworks/jpi';

import { RouteMixin, ScaleMixin } from '@/mixins';
import { ApplicationWizardCard } from '@/components/application-wizard';
import { DocumentUploadRow, SbaFormDetails, HmdaGmiForm } from '@/components';
import BorrowerNotUploadingReasonModal from '@/components/documents/borrower-not-uploading-reason-modal/borrower-not-uploading-reason-modal.vue';

import { SbaFormUtils, ApplicationNavigationUtils } from '../../../utils';
import { fetchCustomerByIds } from '../../../logic/Customer.service';
import { DateFormatting } from '@sageworks/core-logic';
import { ToApplicationTemplateType } from '@/utils/loan-app-type-helper/LoanAppTypeHelper';
import DemographicForms from '../../../components/documents/demographic-forms/demographic-forms.vue';
import { DetailedAggregatedDemographicInformation } from '@/models/aggregated-demographic-information';
import { isInFinalStatus as isApplicationInFinalStatus } from '../../../utils/application-final-status-helper/application-final-status-helper';
import { getCustomerNameFromCustomer as getCustomerName } from '../../../utils/customer-name-helper';

@Component({
	components: {
		DemographicForms,
		FontAwesomeIcon,
		ApplicationWizardCard,
		DocumentUploadRow,
		SbaFormDetails,
		BorrowerNotUploadingReasonModal,
		HmdaGmiForm
	}
})
export default class Documentation extends Mixins(RouteMixin, ScaleMixin) {
	// Replicated in institution settings
	defaultDocumentsPageMessage = 'Please provide the following documents...';

	@Prop({ default: null })
	applicationId!: string;

	formDataLoaded = false;

	customers: Customer[] = [];

	documentSettings: DynamicApplicationDocumentSetting | null = {};

	shouldMatchGeneralMessage = true;

	get demographicInformation() {
		const demographic1071EmailsEnabled = this.demographic1071EmailsEnabled;
		const showGmiForm = this.showGmiForm;
		const customers = this.customers;

		return this.directStore.getters.DemographicForm.demographicInformationByCustomers.map<DetailedAggregatedDemographicInformation>(x => {
			const customer = customers.find(c => c.id === x.customerId);
			return {
				...x,
				demographic1071Summaries: demographic1071EmailsEnabled ? x.demographic1071Summaries : [],
				gmiFormLinks: showGmiForm ? x.gmiFormLinks : [],
				customerName: this.getCustomerNameFromCustomer(customer),
				customerEmail: customer?.emailAddress ?? ''
			};
		});
	}

	get showGmiForm() {
		return this.featureFlags?.has(FeatureFlagging.FeatureFlagEnum.EnableHmdainDya) ?? false;
	}

	get demographic1071EmailsEnabled() {
		return this.featureFlags?.has(FeatureFlagging.FeatureFlagEnum.Enable1071FormEmails) ?? false;
	}

	get documentMessage() {
		if (!this.shouldMatchGeneralMessage && this.directStore.state.UserCreatedMessages.DocumentsPageMessageHtml !== null) {
			return this.directStore.state.UserCreatedMessages.DocumentsPageMessageHtml;
		}
		return this.directStore.state.InstitutionSettings.documentsMessage.message ?? this.defaultDocumentsPageMessage;
	}

	get isLender() {
		return this.directStore.getters.User.isLender;
	}
	get canSubmitBeforeLenderReview(): boolean {
		return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.SubmitApplicationBeforeLenderReview);
	}
	get applicationStatus() {
		return this.directStore.state.LoanApplicationData.loanApplication?.applicationStatus ?? null;
	}
	get loanRequiresReview() {
		return this.directStore.getters.LoanApplicationMetadata.loanRequiresReview;
	}
	get isInFinalStatus(): boolean {
		return isApplicationInFinalStatus(this.applicationStatus);
	}
	get finalTabTitle(): string {
		if (!this.isInFinalStatus && !this.canSubmitBeforeLenderReview && this.loanRequiresReview) {
			return 'Review';
		}
		return 'Submission';
	}

	get featureFlags() {
		return this.directStore.state.FeatureFlag.activeFeatures;
	}

	get itemLabel(): string {
		if (this.hasCommunityLendingSubscription === undefined) {
			return '';
		}
		return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
	}

	get hasCommunityLendingSubscription(): boolean | undefined {
		return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
	}

	get sbaFormsByCustomerId() {
		return this.directStore.getters.SbaForm.sbaFormsByCustomers;
	}

	get applicationDocumentRequests() {
		return this.directStore.state.ApplicationDocumentRequest.documentRequests ?? {};
	}

	get customerIds() {
		return new Set(
			[...Object.keys(this.sbaFormsByCustomerId), ...this.customerIdsWithDocumentRequests, ...this.demographicInformation.map(x => x.customerId)].map(x =>
				Number(x)
			)
		);
	}

	get customerIdsWithDocumentRequests() {
		return new Set([...Object.keys(this.applicationDocumentRequests ?? {})].map(x => Number(x)));
	}

	get isLoading() {
		return !this.formDataLoaded;
	}

	get hasCustomersWithDocumentationNeeds() {
		return this.customersWithDocumentationNeeds.length > 0;
	}

	get customersWithDocumentationNeeds() {
		return this.customers.filter(x => {
			return this.sbaFormsByCustomerId[x.id] != null || this.applicationDocumentRequests[x.id] != null;
		});
	}

	public uploadOtherDocuments(customerId: number): void {
		const { loanApplication } = this.directStore.state.LoanApplicationData;

		if (!loanApplication?.id || !customerId) {
			return;
		}

		const newDocumentRequest = new OnlinePortalApplicationDocumentRequest({
			customerId,
			loanApplicationId: loanApplication.id,
			dateCreated: DateFormatting.formatDateForJpi(new Date())
		});

		this.directStore.dispatch.ApplicationDocumentRequest.addDocumentRequest(newDocumentRequest);
	}
	public getCustomerNameFromCustomer(customer: Customer) {
		return getCustomerName(customer);
	}

	public canShowSbaForm(sbaForm: SbaForm) {
		if (!sbaForm.formType) return false;

		return SbaFormUtils.isFormTypeLenderOnly(sbaForm.formType) ? this.isLender : true;
	}

	// eslint-disable-next-line max-lines-per-function
	public async beforeMount(): Promise<void> {
		const loanApplicationId = Number(this.applicationId);
		if (loanApplicationId == null || Number.isNaN(loanApplicationId)) return;

		const dataLoadPromises: Promise<any>[] = [
			// TODO: the below action calls the same endpoint for the same data as line 272
			this.directStore.dispatch.LoanApplicationData.loadLoanApplication({ loanApplicationId: loanApplicationId }),
			this.directStore.dispatch.LoanApplicationMetadata.loadMetadata({ loanApplicationId: loanApplicationId, ignoreFormioProperties: true }),
			this.directStore.dispatch.ApplicationDocumentRequest.loadPagedDocumentRequests({ loanApplicationId }),
			this.directStore.dispatch.SbaForm.loadSbaForms({ loanApplicationId }),
			this.directStore.dispatch.DemographicForm.loadData({ loanApplicationId: loanApplicationId }),
			this.directStore.dispatch.FeatureFlag.fetchActiveFeatures(),
			this.directStore.dispatch.LenderAuthorizationModule.loadUserPermissions(),
			this.directStore.dispatch.UsableProducts.fetchUsableProducts(),
			this.loadApplicationDependentData(loanApplicationId)
		];
		if (this.directStore.state.DocumentName.documentNames.length === 0) {
			dataLoadPromises.push(this.directStore.dispatch.DocumentName.loadDocumentNames());
		}

		await Promise.all(dataLoadPromises);
		// Now that we gathered all the forms and document requests, we can load the customers
		this.customers = await fetchCustomerByIds(Array.from(this.customerIds));

		// document upload depends on form metadata because it must figure out which loans to associate the
		// 	document to
		await this.directStore.dispatch.MultiLoanApplicationForm.loadAllFormData();
		this.formDataLoaded = true;
	}

	public async loadApplicationDependentData(loanApplicationId: number) {
		await this.directStore.dispatch.Application.fetchApplicationById(loanApplicationId);
		const applicationType = this.directStore.getters.Application.applicationById(loanApplicationId)?.type;

		if (applicationType != null) {
			// Load document settings
			const documentSettingsService = AutoFactory.get(DynamicApplicationDocumentSettingsService);
			this.documentSettings = (await documentSettingsService.getByApplicationType(applicationType)) ?? {};

			// Load document messages
			await this.directStore.dispatch.InstitutionSettings.fetchShouldMatchGeneralDocumentsPageMessage({ applicationType: applicationType });
			this.shouldMatchGeneralMessage = this.directStore.getters.InstitutionSettings.shouldMatchGeneralDocumentsPageMessage;
			if (!this.shouldMatchGeneralMessage) {
				await this.directStore.dispatch.UserCreatedMessages.fetchDocumentsPageMessage(ToApplicationTemplateType(applicationType));
			}

			// Load additional allowable documents
			if (this.directStore.state.DocumentName.additionalAllowableDocuments.length === 0) {
				await this.directStore.dispatch.DocumentName.loadAdditionalAllowableDocuments(applicationType);
			}
		}
	}

	public navigateToApplication() {
		ApplicationNavigationUtils.navigateToApplicationForm(this.$router, this.applicationId);
	}

	public navigateToSubmit() {
		ApplicationNavigationUtils.navigateToApplicationSubmit(this.$router, this.applicationId);
	}
}
