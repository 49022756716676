
import Vue from 'vue';
import moment from 'moment';
import { OnlinePortalApplicationDocumentRequest } from '@/models';

const MARKED_ON_FILE_WAIVED_REASON = 'Document on file';

export default Vue.extend({
	name: 'ApplicationPdfDocumentationSection',
	components: {
	},
	props: {
		loanApplicationId: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			applicationDocumentRequests: [] as OnlinePortalApplicationDocumentRequest[],
		};
	},

	computed: {
		waivedDocuments(): OnlinePortalApplicationDocumentRequest[] {
			return this.applicationDocumentRequests.filter(x => 
			(x.dateWaived && x.waivedReason !== MARKED_ON_FILE_WAIVED_REASON)
				|| this.isRequestNotUploadedByBorrower(x));
		},
		uploadedDocuments(): OnlinePortalApplicationDocumentRequest[] {
			return this.applicationDocumentRequests.filter(x => x.dateUploaded && !this.isRequestNotUploadedByBorrower(x));
		},
		markedOnFileDocuments(): OnlinePortalApplicationDocumentRequest[] {
			return this.applicationDocumentRequests.filter(x => x.dateWaived && x.waivedReason === MARKED_ON_FILE_WAIVED_REASON);
		},
		uploadedDocumentsTableFields() {
			return [
				{ key: 'documentName', label: 'Document Name' },
				{ key: 'dateUploaded', label: 'Upload Date', formatter: this.getFormattedDateString },
				{ key: 'uploadedByUserName', label: 'Uploaded By' }
			];
		},
		markedOnFileDocumentsTableFields() {
			return [
				{ key: 'documentName', label: 'Document Name' },
				{ key: 'dateWaived', label: 'Marked On', formatter: this.getFormattedDateString },
				{ key: 'waivedByLoanOfficerName', label: 'Marked By' }
			];
		},
		waivedDocumentsTableFields() {
			return [
				{ key: 'documentName', label: 'Document Name' },
				{ key: 'dateWaived', label: 'Waived On', formatter: this.getWaivedOnDate },
				{ key: 'waivedByLoanOfficerName', label: 'Waived By', formatter: this.getWaivedByUserName },
				{ key: 'waivedReason', label: 'Reason', formatter: this.getWaivedReason },
			];
		},
		visibleDocumentCount(): number {
			return this.applicationDocumentRequests.filter(x => x.dateWaived || x.dateUploaded).length;
		}
	},
	async mounted() {
		await this.initDocumentRequests();
	},
	methods: {
		async initDocumentRequests(): Promise<void> {
			await this.directStore.dispatch.ApplicationDocumentRequest.loadPagedDocumentRequests({ loanApplicationId: this.loanApplicationId });
			this.applicationDocumentRequests = Object.values(this.directStore.state.ApplicationDocumentRequest.documentRequests ?? {}).flat();
		},
		getFormattedDateString(fullDateString: string): string {
			return moment(fullDateString).format('MM/DD/YYYY');
		},
		getWaivedOnDate(value: string, key: string, item: OnlinePortalApplicationDocumentRequest) {
			return this.getFormattedDateString(this.isRequestNotUploadedByBorrower(item) ? item.dateUploaded : item.dateWaived);
		},
		getWaivedByUserName(value: string, key: string, item: OnlinePortalApplicationDocumentRequest) {
			return this.isRequestNotUploadedByBorrower(item) ? item.uploadedByUserName : item.waivedByLoanOfficerName;
		},
		getWaivedReason(value: string, key: string, item: OnlinePortalApplicationDocumentRequest) {
			return this.isRequestNotUploadedByBorrower(item) ? item.notUploadedReason : item.waivedReason;
		},
		isRequestNotUploadedByBorrower(request: OnlinePortalApplicationDocumentRequest) {
			return request.notUploadedReason != null && request.notUploadedReason !== '';
		}
	},
});
