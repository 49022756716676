import { defineModule } from 'direct-vuex';
import { moduleActionContext } from '.';
import {
	AutoFactory,
	BeneficialOwnersService,
	BusinessInvestmentsService,
	CollateralsService,
	Customer,
	CustomersService,
	DataField,
	DataFieldOption,
	FinancialSubaccountsService,
	OnlinePortalPropertyOptionsService,
	ProposedLoansService,
	RealEstateFinancialStatementsService
} from '@sageworks/jpi';
import { CustomComponentType } from '@sageworks/dynamic-forms/src/enums/custom-component-type';
import { DataFieldAlias, DataObjectPropertiesUtils } from '@sageworks/core-logic';
import { TemplateDataField } from '@sageworks/dynamic-forms';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, BusinessObjectPropertiesModule);

export interface BusinessObjectPropertiesModuleState {
	propertiesByType: { [key: string]: DataField[] };
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number };
	selectFieldOptions: { [key in TemplateDataField]?: DataFieldOption[] };
}
const BusinessObjectPropertiesModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			propertiesByType: {},
			templateDataFieldIdToDataFieldId: {},
			selectFieldOptions: {}
		} as BusinessObjectPropertiesModuleState;
	},
	mutations: {
		SET_PROPERTIES(state, { type, properties }: { type: string; properties: DataField[] }) {
			state.propertiesByType[type] = properties;
		},
		SET_SELECT_FIELD_OPTIONS(state, { templateFieldId, options }: { templateFieldId: TemplateDataField; options: DataFieldOption[] }) {
			state.selectFieldOptions = {
				...state.selectFieldOptions,
				[templateFieldId]: options
			};
		}
	},
	actions: {
		async loadTemplateDataFieldMappings(context) {
			const { state, dispatch } = actionContext(context);
			await Promise.all([
				dispatch.updateLoanProperties(),
				dispatch.updatePeopleProperties(),
				dispatch.updateBusinessProperties(),
				dispatch.updateCollateralProperties(),
				dispatch.updateNonprofitProperties(),
				dispatch.updateFarmProperties(),
				dispatch.updateBeneficialOwnershipProperties(),
				dispatch.updateFinancialSubaccountProperties(),
				dispatch.updateRealEstateFinancialStatementProperties(),
				dispatch.updateBusinessInvestmentProperties()
			]);

			const convertDataField = (dataField: DataField) => {
				if (dataField.templateDataFieldId != null) {
					state.templateDataFieldIdToDataFieldId[dataField.templateDataFieldId] = dataField.id as number;
				}
			};
			state.propertiesByType[CustomComponentType.loans].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.collateralInfo].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.personalInfo].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.businessInfo].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.nonprofitInfo].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.farmInfo].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.beneficialOwnership].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.financialSubaccount].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.investmentRealEstate].forEach(convertDataField);
			state.propertiesByType[CustomComponentType.businessInvestment].forEach(convertDataField);
		},
		async updateProperties(context, objectType: CustomComponentType): Promise<DataField[]> {
			const { dispatch } = actionContext(context);
			switch (objectType) {
				case CustomComponentType.loans:
					return dispatch.updateLoanProperties();
				case CustomComponentType.personalInfo:
					return dispatch.updatePeopleProperties();
				case CustomComponentType.businessInfo:
					return dispatch.updateBusinessProperties();
				case CustomComponentType.collateralInfo:
					return dispatch.updateCollateralProperties();
				case CustomComponentType.nonprofitInfo:
					return dispatch.updateNonprofitProperties();
				case CustomComponentType.farmInfo:
					return dispatch.updateFarmProperties();
				case CustomComponentType.beneficialOwnership:
					return dispatch.updateBeneficialOwnershipProperties();
				case CustomComponentType.investmentRealEstate:
					return dispatch.updateRealEstateFinancialStatementProperties();
				case CustomComponentType.businessInvestment:
					return dispatch.updateBusinessInvestmentProperties();
			}
			return Promise.reject();
		},
		async updateLoanProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(ProposedLoansService).getPagedProperties(pageNumber, limit, undefined, undefined, undefined, undefined, fields)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.loans, properties: properties });

			return properties;
		},
		async updatePeopleProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(CustomersService).getPagedProperties(
						Customer.TypeEnum.Person,
						pageNumber,
						limit,
						undefined,
						undefined,
						undefined,
						undefined,
						fields
					)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.personalInfo, properties: properties });

			return properties;
		},
		async updateBusinessProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(CustomersService).getPagedProperties(
						Customer.TypeEnum.Business,
						pageNumber,
						limit,
						undefined,
						undefined,
						undefined,
						undefined,
						fields
					)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.businessInfo, properties: properties });

			return properties;
		},
		async updateNonprofitProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(CustomersService).getPagedProperties(
						Customer.TypeEnum.NonProfit,
						pageNumber,
						limit,
						undefined,
						undefined,
						undefined,
						undefined,
						fields
					)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.nonprofitInfo, properties: properties });

			return properties;
		},
		async updateFarmProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(CustomersService).getPagedProperties(
						Customer.TypeEnum.Farm,
						pageNumber,
						limit,
						undefined,
						undefined,
						undefined,
						undefined,
						fields
					)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.farmInfo, properties: properties });

			return properties;
		},
		async updateCollateralProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(CollateralsService).getPagedProperties(pageNumber, limit, undefined, undefined, undefined, undefined, fields)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.collateralInfo, properties: properties });

			return properties;
		},
		async updateBeneficialOwnershipProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(BeneficialOwnersService).getPagedProperties(pageNumber, limit, undefined, undefined, undefined, undefined, fields)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.beneficialOwnership, properties: properties });

			return properties;
		},
		async updateFinancialSubaccountProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(FinancialSubaccountsService).getPagedProperties(pageNumber, limit, undefined, undefined, undefined, undefined, fields)
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.financialSubaccount, properties: properties });

			return properties;
		},
		async updateRealEstateFinancialStatementProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const allowedFields = [
				DataFieldAlias.RealEstatePropertyName,
				DataFieldAlias.RealEstatePropertyValue,
				DataFieldAlias.RealEstatePercentOwnership,
				DataFieldAlias.RealEstatePropertyType,
				DataFieldAlias.RealEstateOwnerFinancialDataId,
				DataFieldAlias.RealEstatePropertyId
			];
			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(RealEstateFinancialStatementsService).getPagedProperties(
						pageNumber,
						limit,
						undefined,
						undefined,
						undefined,
						undefined,
						fields
					),
				allowedFields
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.investmentRealEstate, properties: properties });

			return properties;
		},
		async updateBusinessInvestmentProperties(context): Promise<DataField[]> {
			const { commit } = actionContext(context);

			const allowedFields = [
				DataFieldAlias.BusinessInvestmentValue,
				DataFieldAlias.BusinessInvestmentEntityId,
				DataFieldAlias.BusinessInvestmentOwnerId,
				DataFieldAlias.BusinessInvestmentFinancialDataId
			];
			const properties = await DataObjectPropertiesUtils.fetchAllProperties({
				fetch: (pageNumber, limit, fields) =>
					AutoFactory.get(BusinessInvestmentsService).getPagedProperties(pageNumber, limit, undefined, undefined, undefined, undefined, fields),
				allowedFields
			});
			commit.SET_PROPERTIES({ type: CustomComponentType.businessInvestment, properties: properties });

			return properties;
		},
		async loadSelectFieldOptions(context, { templateFieldId }: { templateFieldId: TemplateDataField }) {
			const { state, commit } = actionContext(context);

			if (state.selectFieldOptions[templateFieldId]) {
				return;
			}

			const roleTypeFieldId = state.templateDataFieldIdToDataFieldId[templateFieldId];
			const service = AutoFactory.get(OnlinePortalPropertyOptionsService);

			const response = await service.getSingle(roleTypeFieldId, 1, 1000);
			commit.SET_SELECT_FIELD_OPTIONS({ templateFieldId, options: response?.items ?? [] });
		}
	}
});

export default BusinessObjectPropertiesModule;
