import { AutoFactory, OnlinePortalCustomersService as CustomersService, Customer } from '@sageworks/jpi';

export const fetchCustomerByIds = async (customerIds: number[]): Promise<Customer[]> => {
	if (customerIds.length === 0) {
		return Promise.resolve([]);
	}
	const customersService = AutoFactory.get(CustomersService);
	// NOTE: the jpi page size is max at 1000
	const response = await customersService.getPaged(1, 1000, undefined, customerIds);
	return response.items ?? [];
};
