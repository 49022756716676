import { defineModule } from 'direct-vuex';
import {
	AutoFactory,
	OnlinePortalAuthenticationService,
	TokenService,
	OnlinePortalRegistrationRequest,
	OnlinePortalAuthRequest,
	OnlinePortalUsersService,
	OnlinePortalAuth0Service
} from '@sageworks/jpi';
import { SetupApiUtils, UrlUtils, LocalStorageUtils } from '@/utils';
import { LocalStorageKeys } from '@/enums';
// Using moduleActionContext instead of localActionContext because we need access to rootGetters
import { moduleActionContext } from '.';
import { TOKEN_LIFESPAN_REFRESH_INTERVAL } from '@sageworks/jpi/src/token.service';
import { useAuth0 } from '@/composables';

const { auth0 } = useAuth0();

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, AuthenticationModule);
const ONE_SECOND_IN_MS = 1000;
export interface AuthenticationModuleState {
	userToken: string | null;
	deviceToken: string | null;
	expiresIn: number | null;
	onlinePortalUserToIdVerify: number | null;
	refreshTokenWatcherIntervalId: number;
	lastEmailVerificationSent: string | null;
	newAccount: boolean;
	apiToken: string | null;
	_auth0FFEnabled: boolean | null;
}
const AuthenticationModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			userToken: null,
			deviceToken: null,
			expiresIn: null,
			onlinePortalUserToIdVerify: null,
			refreshTokenWatcherIntervalId: 0,
			lastEmailVerificationSent: null,
			newAccount: true,
			apiToken: null,
			_auth0FFEnabled: null
		} as AuthenticationModuleState;
	},
	mutations: {
		SET_ONLINE_PORTAL_USER_TO_ID_VERIFY(state, onlinePortalUserId: number | null) {
			state.onlinePortalUserToIdVerify = onlinePortalUserId;
		},
		SET_USER_TOKEN(state, userToken: string) {
			state.userToken = userToken;
			LocalStorageUtils.setItem(LocalStorageKeys.userToken, userToken);
		},
		SET_DEVICE_TOKEN(state, payload: { deviceToken: string; rememberThisDevice: boolean }) {
			state.deviceToken = payload.deviceToken;
			if (payload.rememberThisDevice) {
				LocalStorageUtils.setItem(LocalStorageKeys.deviceToken, payload.deviceToken);
			}
			state.onlinePortalUserToIdVerify = null;
		},
		CLEAR_DEVICE_TOKEN(state) {
			state.deviceToken = null;
			const deviceToken = LocalStorageUtils.getItem(LocalStorageKeys.deviceToken);
			if (deviceToken) {
				LocalStorageUtils.removeItem(LocalStorageKeys.deviceToken);
			}
		},
		SET_REFRESH_TOKEN_WATCHER_INTERVAL_ID(state, refreshTokenWatcherIntervalId: number) {
			if (state.refreshTokenWatcherIntervalId !== refreshTokenWatcherIntervalId) {
				clearInterval(state.refreshTokenWatcherIntervalId);
			}
			state.refreshTokenWatcherIntervalId = refreshTokenWatcherIntervalId;
		},
		SET_EXPIRATION(state, expiration: number) {
			state.expiresIn = expiration * ONE_SECOND_IN_MS;
		},
		SET_LAST_EMAIL_VERIFICATION_SENT(state, userEmail: string) {
			state.lastEmailVerificationSent = userEmail;
		},
		SET_NEW_ACCOUNT(state, newAccount: boolean) {
			state.newAccount = newAccount;
		},
		CLEAR_EXPIRATION(state) {
			state.expiresIn = null;
		},
		CLEAR_USER_TOKEN(state) {
			state.userToken = null;
			LocalStorageUtils.removeItem(LocalStorageKeys.userToken);
		},
		SET_API_TOKEN(state, apiToken: string) {
			state.apiToken = apiToken;
		},
		SET_AUTH0FF_ENABLED(state, enabled: boolean) {
			state._auth0FFEnabled = enabled;
		}
	},
	getters: {
		deviceToken(state): string | null {
			return state.deviceToken ?? LocalStorageUtils.getItem(LocalStorageKeys.deviceToken);
		}
	},
	actions: {
		async login(context, { username, secret }: { username?: string; secret?: string }): Promise<boolean> {
			const { commit, dispatch, getters } = actionContext(context);
			const onlinePortalAuthService: OnlinePortalAuthenticationService = AutoFactory.get(OnlinePortalAuthenticationService);
			const tokenService: TokenService = AutoFactory.get(TokenService);
			commit.SET_ONLINE_PORTAL_USER_TO_ID_VERIFY(null);
			const authResponse = await onlinePortalAuthService.getToken({
				username,
				secret,
				subdomain: UrlUtils.getOnlinePortalSubdomain(),
				deviceToken: getters.deviceToken
			} as OnlinePortalAuthRequest);
			commit.SET_NEW_ACCOUNT(authResponse.newAccount ?? false);
			if (authResponse.token) {
				commit.SET_USER_TOKEN(authResponse.token);
				await tokenService.get().then(async token => {
					commit.SET_EXPIRATION(token.expires_in);
					commit.SET_API_TOKEN(token.access_token);
					await dispatch.refreshToken();
					SetupApiUtils.setApiAccessToken(token.access_token);
				});
				return true;
			} else if (!authResponse.deviceVerified && authResponse.onlinePortalUserId) {
				await dispatch.setUser({ username, secret });
				commit.SET_ONLINE_PORTAL_USER_TO_ID_VERIFY(authResponse.onlinePortalUserId);
				return true;
			}
			return Promise.reject(new Error('Failed to authenticate user'));
		},
		async setUser(context, { username, secret }: { username?: string; secret?: string }): Promise<void> {
			const { rootCommit } = actionContext(context);
			const onlinePortalAuthService: OnlinePortalAuthenticationService = AutoFactory.get(OnlinePortalAuthenticationService);
			rootCommit.User.SET_USER(
				await onlinePortalAuthService.getBankCustomerUser({
					username,
					secret,
					subdomain: UrlUtils.getOnlinePortalSubdomain()
				})
			);
		},
		async isAuth0Enabled(context): Promise<boolean> {
			const { state, commit } = actionContext(context);
			if (state._auth0FFEnabled === null) {
				const auth0FF = await AutoFactory.get(OnlinePortalAuth0Service).isAuth0FeatureFlagActive({ subdomain: UrlUtils.getOnlinePortalSubdomain() });
				commit.SET_AUTH0FF_ENABLED(auth0FF.hasAccess ?? false);
			}
			return state._auth0FFEnabled ?? false;
		},
		async logout(context): Promise<void> {
			const { rootState, commit, dispatch } = actionContext(context);
			const usersService = AutoFactory.get(OnlinePortalUsersService);
			await usersService.logOut();
			commit.CLEAR_USER_TOKEN();
			commit.CLEAR_EXPIRATION();
			if (await dispatch.isAuth0Enabled()) {
				const redirectUrl = UrlUtils.getLogoutRedirectUrl();
				auth0.client?.logout({
					logoutParams: {
						returnTo: redirectUrl
					}
				});
			} else {
				let redirectUrl: string = rootState.InstitutionSettings.logoutUrl;
				if (redirectUrl) {
					location.replace(redirectUrl);
				} else {
					location.reload();
				}
			}
		},
		async validateSession(context): Promise<boolean> {
			const { state, commit, dispatch } = actionContext(context);
			const localStorageUserToken: string | null = LocalStorageUtils.getItem(LocalStorageKeys.userToken);

			if ((await dispatch.isAuth0Enabled()) && !state.userToken && !localStorageUserToken) {
				return await dispatch.validateAuth0Session();
			}

			if (!state.userToken && !localStorageUserToken) {
				return false;
			}

			if (localStorageUserToken && !state.userToken) {
				commit.SET_USER_TOKEN(localStorageUserToken);
			}
			if (state.userToken && !localStorageUserToken) {
				commit.SET_USER_TOKEN(state.userToken);
			}

			const tokenService: TokenService = AutoFactory.get(TokenService);
			return await tokenService
				.get()
				.then(async token => {
					commit.SET_EXPIRATION(token.expires_in);
					await dispatch.refreshToken();
					commit.SET_API_TOKEN(token.access_token);
					return SetupApiUtils.setApiAccessToken(token.access_token);
				})
				.catch(() => {
					return false;
				});
		},

		async validateAuth0Session(context): Promise<boolean> {
			const { commit, dispatch } = actionContext(context);
			if (auth0.loading) {
				await auth0.loadingPromise;
			}

			if (!auth0.isAuthenticated) {
				return false;
			}

			const auth0Token = (await auth0.client?.getIdTokenClaims())?.__raw;
			const tokenService: TokenService = AutoFactory.get(TokenService);
			return tokenService
				.getTokenWithAuth0Token(auth0Token)
				.then(async token => {
					commit.SET_EXPIRATION(token.expires_in);
					await dispatch.refreshToken();
					commit.SET_API_TOKEN(token.access_token);
					return SetupApiUtils.setApiAccessToken(token.access_token);
				})
				.catch(() => {
					return false;
				});
		},

		async refreshToken(context): Promise<void> {
			const { state, commit } = actionContext(context);
			const tokenService: TokenService = AutoFactory.get(TokenService);
			if (state.refreshTokenWatcherIntervalId !== 0 || state.expiresIn == null) return;
			commit.SET_REFRESH_TOKEN_WATCHER_INTERVAL_ID(
				setInterval(() => {
					tokenService.get().then(token => {
						SetupApiUtils.setApiAccessToken(token.access_token);
						commit.SET_API_TOKEN(token.access_token);
					});
				}, state.expiresIn * TOKEN_LIFESPAN_REFRESH_INTERVAL)
			);
		},

		async createUserAccount(_, registrationRequest: OnlinePortalRegistrationRequest): Promise<boolean> {
			const onlinePortalAuthService: OnlinePortalAuthenticationService = AutoFactory.get(OnlinePortalAuthenticationService);
			try {
				const registrationResponse = await onlinePortalAuthService.register(registrationRequest);
				return Promise.resolve(registrationResponse?.identityVerified ?? false);
			} catch (err) {
				return Promise.reject(err);
			}
		},
		async sendVerificationEmail(context, payload: { subdomain: string; email: string }) {
			const { commit } = actionContext(context);
			const onlinePortalAuthService: OnlinePortalAuthenticationService = AutoFactory.get(OnlinePortalAuthenticationService);
			try {
				await onlinePortalAuthService.sendVerificationEmail(payload.subdomain, payload.email);
				commit.SET_LAST_EMAIL_VERIFICATION_SENT(payload.email);
			} catch (err) {
				return Promise.reject(err);
			}
		},
		async sendPasswordResetEmail(): Promise<boolean> {
			// Replace promise with password reset email sending method
			// Email will already be in the store when this is hit
			// if (false) {
			// 	Promise.resolve(true);
			// }
			return Promise.reject(new Error('Failed to send password reset email'));
		},
		async resetUserPassword(_, newPassword: string): Promise<boolean> {
			// Replace promise with password reset method
			if (newPassword) {
				return Promise.resolve(true);
			}
			return Promise.reject(new Error('Failed to reset user password'));
		},
		clearDeviceToken(context): void {
			const { commit } = actionContext(context);
			commit.CLEAR_DEVICE_TOKEN();
		}
	}
});

export default AuthenticationModule;
