import {
	AutoFactory,
	OnlinePortalLoanApplicationsService,
	OnlinePortalStartWorkflowRequest,
	OnlinePortalWorkflowService,
	UsableProducts
} from '@sageworks/jpi';
import { defineModule } from 'direct-vuex';
import { FormSubmissionDataUtils} from '@sageworks/dynamic-forms';
import { moduleActionContext } from '.';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, StartApplicationModule);

export interface StartApplicationModuleState {
	applicationId: number | null;
}
const StartApplicationModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			applicationId: null
		} as StartApplicationModuleState;
	},
	mutations: {
		SET_APPLICATION_ID(state, applicationId: number | null) {
			state.applicationId = applicationId;
		}
	},
	actions: {
		async startApplication(context, preAppData: FormSubmissionDataUtils.ApplicationStartDto): Promise<void> {
			const { rootState, commit } = actionContext(context);
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			const workflowService = AutoFactory.get(OnlinePortalWorkflowService);
			const response = await loanApplicationService.startApplication(
				preAppData.applicationType!,
				preAppData.proposedProductsId!,
				preAppData.appStartRequest
			);
			const applicationId = response?.id ?? null;
			const hasLosLiteSubscription = rootState.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);

			if (applicationId != null && hasLosLiteSubscription) {
				const startWorkflowRequest: OnlinePortalStartWorkflowRequest = {
					loanApplicationId: applicationId,
					bankCustomerUsersId: rootState.User.user?.id ?? undefined
				};
				workflowService.startWorkflowForApplication(startWorkflowRequest);
			}

			commit.SET_APPLICATION_ID(applicationId);
		}
	},
	getters: {
		applicationId(state): number {
			return state?.applicationId ?? 0;
		}
	}
});

export default StartApplicationModule;
