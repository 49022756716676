
import Vue, { PropType } from 'vue';
import { EmailLog } from '@sageworks/jpi';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import moment from 'moment';

export default Vue.extend({
	name: 'DemographicStatusBadge',
	components: {
		FontAwesomeIcon
	},
	props: {
		emailLogs: {
			type: Array as PropType<EmailLog[]>,
			required: true
		},
		customerId: {
			type: Number,
			required: true
		}
	},
	computed: {
		label(): string {
			return ` ${this.emailLogs.length} sent`;
		},
		tooltip() {
			if (this.emailLogs.length === 0) {
				return 'No requests have been sent.';
			}

			let label = '';
			for (let index = 0; index < this.emailLogs.length; index++) {
				label = label.concat(`Request ${index + 1}:  Sent ${this.convertDate(this.emailLogs[index].dateTime)}\n`);
			}
			return label;
		}
	},
	methods: {
		convertDate(date: string | undefined): string {
			if (!date) {
				return '';
			}
			return moment(date).format('MM/DD/YYYY hh:mm A');
		}
	}
});
