
import { FormioComponentType } from '@sageworks/dynamic-forms';
import { RenderUtils } from '../../utils';
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'Form',
	computed: {
		childComponents() {
			const componentTree = this.exportMetadata.ComponentTree;
			return RenderUtils.getChildComponents(componentTree);
		},
		formattedChildComponents() {
			const childComponents = this.childComponents as any[];
			return childComponents.map(child => ({
				componentRenderType: this.getComponent(child.type),
				component: child,
				exportMetadata: this.buildExportMetadata(child),
				props: this.createChildProps(child)
			}));
		},
		dataLookup() {
			return this.directStore.state.PDFPreviewModule.renderData[this.exportMetadata.LoanIndex].configurationLookup ?? {};
		}
	},
	components: {},
	methods: {
		createChildProps(childComponent: any) {
			switch (childComponent.type) {
				case FormioComponentType.Panel:
					return { hideHeader: this.childComponents.length === 1 };
				default:
					return {};
			}
		},
		hasDataInChildren(child: any) {
			if (child.component === undefined) {
				return false;
			}
			if (child.component.forceRenderInPdf === true) {
				return true;
			}
			let properties = Object.keys(this.dataLookup);
			let panelPath = child.component.id;
			for (const key of properties) {
				if (panelPath !== '' && key.includes(panelPath)) {
					let value = this.dataLookup[key];
					if (value.hasData) {
						return true;
					}
				}
			}
			return false;
		}
	}
});
